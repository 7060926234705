import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { get } from '../utils/axiosTvs';

interface Props {
  cmsPath: string;
}

const CmsPage: React.FC<Props> = ({ cmsPath }) => {
  const location = useLocation();
  const [page, setPage] = useState<string>();

  const path = location.search ? cmsPath + location.search : cmsPath;

  useEffect(() => {
    get(path).then((value) => {
      if (path === 'naujienos') {
        const html = new DOMParser().parseFromString(value.data, 'text/html');
        const element = html.querySelector('h2 + div');
        if (element != null) {
          element.setAttribute('style', 'display: flex; flex-wrap: wrap;');
          const linkHTML = html.getElementsByTagName('link')[0].outerHTML;
          const styleHTML = html.getElementsByTagName('style')[0].outerHTML;
          const content = html.querySelector('body');
          if (content != null) {
            setPage(linkHTML + styleHTML + content.innerHTML);
          } else {
            setPage(value.data);
          }
        }
      } else {
        setPage(value.data);
      }
    });
  }, [path]);

  const replaceLocationIn = (source: string) => source.replace(/@@@baseUrl@@@?q=/g, '/');

  if (page) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceLocationIn(page)
        }}
      />
    );
  }
  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default CmsPage;
